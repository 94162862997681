import { Component, Input, TemplateRef } from "@angular/core";
import { NgTemplateOutlet } from "@angular/common";

@Component({
    selector: 'dige-status-tooltip',
    templateUrl: './status-custom-tooltip.component.html',
    imports: [NgTemplateOutlet]
})
export class StatusCustomTooltipComponent {
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in ToolTipRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate: TemplateRef<any>;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
