import {
  AfterViewInit,
  Component,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CellTemplateDirective } from 'src/app/shared/directives/cell-template.directive';
import { DataService } from 'src/app/core/services/global-data/data.service';
import { NgIf } from '@angular/common';
import { CellTemplateDirective as CellTemplateDirective_1 } from '../../../directives/cell-template.directive';
import { MatIcon } from '@angular/material/icon';
import { ToolTipRendererDirective } from 'src/app/shared/directives/custom-tooltip.directive';

@Component({
    templateUrl: './cell-template.component.html',
    selector: 'dige-cell-template',
    imports: [CellTemplateDirective_1, NgIf, MatIcon, ToolTipRendererDirective]
})
export class CellTemplateComponent implements AfterViewInit {
  @ViewChildren(CellTemplateDirective)
  viewChildrenRef: QueryList<CellTemplateDirective>;
  constructor(private dataService: DataService) {}

  ngAfterViewInit(): void {
    this.viewChildrenRef.forEach((view: CellTemplateDirective): void => {
      this.dataService.cellTemplates.set(view.name, view.template);
    });
  }
}
