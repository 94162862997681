import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private isLoggingEnabled = environment.enableLogging; // Disable in production

  log(...args: any[]): void {
    if (this.isLoggingEnabled) {
      console.log(...args);
    }
  }

  warn(...args: any[]): void {
    if (this.isLoggingEnabled) {
      console.warn(...args);
    }
  }

  error(...args: any[]): void {
    if (this.isLoggingEnabled) {
      console.error(...args);
    }
  }
}
