import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  cellTemplates: Map<string, TemplateRef<any>> = new Map();

  constructor() {}

  get metaFields(): any[] {
    return [
      { key: 'JOB_NUM', name: 'Job Number' },
      { key: 'EVENT_ID', name: 'Event Id' },
      { key: 'EVENT_TYPE', name: 'Event Type' },
      { key: 'FRAME_TYPE', name: 'Frame Type' },
      { key: 'SHOP_ORDER', name: 'Shop Order' },
      { key: 'REPORT_TYPE', name: 'Report Type' },
      { key: 'SUBJECT_TITLE', name: 'Subject Title' },
    ];
  }

  get metaData() {
    return {
      JOB_NUM: '',
      EVENT_ID: '',
      EVENT_TYPE: '',
      FRAME_TYPE: '',
      TURBINE_SHOP_ORDER: '',
      REPORT_TYPE: '',
      SHOP_ORDER: '',
      SUBJECT_TITLE: '',
    };
  }

  setUserSpecificFilterState(state: boolean): void {
    localStorage.setItem('userSpecificFilter', JSON.stringify(state));
  }

  getUserSpecificFilterState(): boolean {
    if (!localStorage.getItem('userSpecificFilter')) {
      this.setUserSpecificFilterState(true);
    }
    return JSON.parse(localStorage.getItem('userSpecificFilter'));
  }
}
